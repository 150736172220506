<template>
<div id="pcrcn">
  <div class="hidden-sm-and-down">
    <PageHeader clinic-name="築地" path-name="tsukiji"/>
    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15">

        <div class="content">
          <div class="h1">中国渡航者の方へ</div>

          <div class="text">当院では、中国渡航者向けにPCR検査の受付を行なっています。 </div>
          <div class="text">診療内容：PCR検査・抗体検査証明書、初診料</div>
          <div class="text">金額：19,800円(税込)</div>

          <div class="text">ご予約方法</div>
          <div class="text">電話予約：03-6260-6008 (のぞみクリニック築地)</div>

          <div class="text">メールでのお問い合わせは<span class="link" @click="goTo('/sending')">こちら</span></div>
        </div>
          

        <div>
          <img src="@/assets/time.png" style="width:100%;" alt="">
        </div>

      </el-col>
    </el-row>
    <!-- <HomeAccess/> -->

    <PageFooter/>
  </div>

  <!-- 手机页面 -->
  <div class="hidden-md-and-up">
    <m-top/>

    <div class="m-content">
      <div class="h1">中国渡航者の方へ</div>

      <div class="text">当院では、中国渡航者向けにPCR検査の受付を行なっています。 </div>
      <div class="text">診療内容：PCR検査・抗体検査証明書、初診料</div>
      <div class="text">金額：19,800円(税込)</div>

      <div class="text">ご予約方法</div>
      <div class="text">電話予約：03-6260-6008 (のぞみクリニック築地)</div>

      <div class="text">メールでのお問い合わせは<span class="link" @click="goTo('/sending')">こちら</span></div>
    </div>

    <div>
      <img src="@/assets/time.png" style="width:100%;" alt="">
      <!-- <el-image :src="imgUrl"></el-image> -->
    </div>
    <!-- <m-access/> -->

    <m-footer/>

  </div>

</div>
</template>

<script>
import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter";
import MTop from "../components/m/MTop";
import MFooter from "../components/m/MFooter";
import MAccess from "../components/m/MAccess";
import HomeAccess from "@/components/home/HomeAccess";
export default {
  name: "PcrCN",
  data() {
    return {
      imgUrl: '@/assets/time.png'
    }
  },
  components: {MAccess, MFooter, MTop, PageFooter, PageHeader, HomeAccess},
  methods: {
    goTo(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style scoped>
#pcrcn {
  border-top: 10px solid #1796c4;
}

.content {
  margin: 4rem 0;
}
.content .h1 {
  color: #707070;
  font-size: 24px;
  margin: 2rem 0;
}
.content .text {
  color: #707070;
  font-size: 18px;
  line-height: 2rem;
}
.content>.text:nth-child(4), .content>.text:nth-child(6) {
  margin-bottom: 2rem;
}

.link {
  color: #1796C4;
}
.link:hover {
  cursor: pointer;
  text-decoration: underline;
}

/*手机页面显示*/
.m-content {
  margin: 3rem 0;
}
.m-content .h1 {
  color: #333;
  font-size: 24px;
  margin: 2rem 0;
  text-align: center;
}
.m-content .text {
  color: #555;
  font-size: 18px;
  line-height: 1.5rem;
  margin-bottom: 8px;
  margin-left: 2rem;
  margin-right: 2rem;
}
.m-content>.text:nth-child(4), .m-content>.text:nth-child(6) {
  margin-bottom: 2rem;
}
</style>
